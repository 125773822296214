import React from 'react'
import Layout from 'components/Layout'
import AppStateProvider from './../state'
import { Container } from 'components/PageContainer'
import './styles/privacy.scss'

function PrivacyIndex() {


    return (
        <AppStateProvider>
            <Layout title="Privacy | SwiftDoc">

                <header className="privacy-header">
                    <h1 className="privacy-title">SwiftDoc Privacy Statement</h1>
                </header>
                <div style={{ padding: '20px', margin: '0 auto', maxWidth: '800px', background: '#fff' }}>
                    <h2 className="privacy-subtitle">1. Your right to privacy</h2>
                    <p>SwiftDoc understands the importance of protecting individuals' rights to privacy and that
                        you may be concerned about the confidentiality of information that you provide us. We
                        have prepared this statement to help you understand how we aim to protect the privacy of
                        your personal information.</p>
                    <p>As a patient of SwiftDoc, we have a duty to you to maintain strict confidentiality in respect
                        of your affairs. In handling personal information, we are subject to the Privacy Act 1998 (Cth)
                        and are required to comply with the National Privacy Principles in the Act.</p>
                    <p className="privacy-text">This policy describes the way we collect, use and discloses your personal information and
                        your rights in relation to our management of your personal information. We may update this
                        policy from time to time.</p>
                    <p className="privacy-text">You should carefully consider this Privacy Statement before submitting personal information
                        to us.</p>

                    <h2>2. What kinds of personal information do we collect?</h2>
                    <p className="privacy-text">Personal information is information that identifies an individual. During the provision of our
                        services to you, we may collect your personal information.</p>
                    <p className="privacy-text">Generally, the kinds of personal information we collect include:</p>
                    <ul>
                        <li>name, address, telephone number, Medicare number and email address;</li>
                        <li>date of birth</li>
                        <li>sex;</li>
                        <li>nationality and ethnic background</li>
                        <li>occupation;</li>
                        <li>next of kin details;</li>
                        <li>General Practitioner;</li>
                        <li>Religion;</li>
                        <li>Sexual preferences and practices;</li>
                        <li>Financial details associated with services we have provided;</li>
                        <li>Medical history;</li>
                        <li>Current and past records of treatment given by our medical practitioners;</li>
                        <li>Any additional information provided to us by you.</li>
                    </ul>
                    <p className="privacy-text">We also hold or compile other personal information provided by you directly or indirectly
                        over our website, www.swiftdoc.com (Website), including computer sign-on data, statistics on page
                        views, traffic to and from our Website, your personal website preferences and ad data.</p>
                    <p className="privacy-text">We may collect any other personal or financial information about you that you provide us in
                        exchange for those of our services that incur a fee. Where you must enter financial
                        information online to pay a fee to us, we engage a third party to provide a secure payment
                        transaction facility and we will not receive your financial information.</p>

                    <h2>3. How do we collect your personal information?</h2>
                    <p className="privacy-text">We will attempt at all times to collect your health and personal information directly from you or from someone whose care you are lawfully under. For example, when you register to make an appointment via our Website or undertake a consultation with one of our general medical practitioners, you need to provide us with your personal information.</p>
                    <p className="privacy-text">Your health and personal information will be seen by administration staff as well as by our medical practitioners.</p>
                    <p className="privacy-text">We or our service providers may also place "cookies" (small files placed on the hard drive of your computer) and “web beacons” (electronic images placed in the web page code) on your computer when you visit some parts of our Website. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive when you close your browser. We may use permanent or session cookies to help analyze web page flow, customize services, content and advertising, and promote trust and safety. You can block our cookies by changing the settings on your browser, but doing so may hinder your use of some of our services.</p>
                    <p className="privacy-text">There may be occasions when we collect your personal information from other sources such as from other users who provide us with a referral or complaint.</p>

                    <h2>4. Will my phone call be recorded?</h2>
                    <p className="privacy-text">We may record our telephone calls with you and will comply with relevant legislation.</p>

                    <h2>5. What about my health records?</h2>
                    <p className="privacy-text">We will comply with legislation relating to health records as applicable.</p>

                    <h2>6. Why do we collect your personal information?</h2>
                    <p className="privacy-text">We collect your personal information to provide the best possible service.</p>
                    <p className="privacy-text">If you do not provide us with your personal information, we may not be able to assist you in providing you with our services, or the level of service you require.</p>
                    <p className="privacy-text">As part of providing our services to you, we may collect your personal information to give you the option of receiving our updates and publications. You agree to receive these communications from us unless you tell us that you would prefer not to receive them. If you do not wish to receive marketing communications from us, you can indicate your preference by following the directions provided in the communication.</p>
                    <p className="privacy-text">We also collect your personal information to help our Website work and troubleshoot problems or resolve disputes, collect fees, and to keep our Website protected from misuse.</p>
                    <p className="privacy-text">We do not sell, rent, or trade personal information about you to third parties for their marketing purposes without your consent.</p>

                    <h2>7. Who will we disclose your personal information to?</h2>
                    <p className="privacy-text">We disclose your personal information for the purposes for which we collect it. That is:</p>
                    <ol>
                        <li>to provide our services to you, including medical treatment and associated communications;</li>
                        <li>to assist treating doctors and allied health professionals in providing medical treatment and care to you;</li>
                        <li>to assist with any calls you may make to us;</li>
                        <li>provide you with assistance in relation to our services;</li>
                        <li>for our internal administrative requirements;</li>
                        <li>to provide information to medical practitioners, registered nurses, and allied health professionals who provide follow-up treatment and ongoing care, including sending your clinical notes and information relating to your consultation with our medical practitioners to other healthcare providers including, subject to your consent, your usual general medical practitioner;</li>
                        <li>for benchmarking and clinical indicator reporting in a de-identified form;</li>
                        <li>for quality initiatives;</li>
                        <li>to provide data in both an identified and de-identified form to state government agencies in compliance with numerous legislative requirements;</li>
                        <li>for training and education purposes with information in a de-identified form;</li>
                        <li>to manage our Website and the services provided to you, including billing for our services and collecting outstanding payments;</li>
                        <li>to verify your identity, if required;</li>
                        <li>to enable you to access our Website and appointment booking portal; or</li>
                        <li>to improve, develop, and maintain the operation of our Website.</li>
                    </ol>
                    <p className="privacy-text">To provide our services, we may disclose your information to third parties that we engage to perform administrative or other services. This disclosure is always on a confidential basis.</p>
                    <p className="privacy-text">If our company or business is bought by someone else, we may share personal information with the buyer, but they must still comply with this policy.</p>
                    <p className="privacy-text">We may also disclose your personal information if you give us your consent or if disclosure is required by law.</p>

                    <h2>8. Security of your personal information</h2>
                    <p className="privacy-text">We take all reasonable steps to ensure that the personal information that we hold is protected against misuse, loss, unauthorized access, modification, or disclosure. We hold personal information in both hard copy and electronic forms in secure databases accessible only to authorized staff.</p>

                    <h2>9. Links to other websites</h2>
                    <p className="privacy-text">Links to other websites and third-party resources may be provided from our Website. You acknowledge that we cannot and do not control the content of these websites and they do not form part of the Website. Different privacy rights and obligations may apply to those websites.</p>

                    <h2>10. Can you access the personal information we hold about you?</h2>
                    <p className="privacy-text">You have a right to access the personal information we collect and hold about you. If at any time you would like to access or change your details, or you would like more information on our approach to privacy, please contact us.</p>
                    <p className="privacy-text">In most cases, a summary of your personal information, such as your name and contact details, is available for no fee. If providing you with access to specific personal information requires a more detailed retrieval of your information, a fee may be charged for the cost of the retrieval and supply of that information.</p>

                    <h2>11. How to contact us or make a complaint</h2>
                    <p className="privacy-text">We have an appointed Privacy Compliance Officer to assist you with any inquiries, complaints, or feedback you may have, who can be contacted at admin@swiftdoc.com.</p>
                    <p className="privacy-text">Complaints may also be made to a relevant regulator such as the Australian Information Commissioner (www.oaic.gov.au or 1300 363 992).</p>

                    <p className="privacy-text">Updated 12/02/2018</p>
                    <p className="privacy-text">The content on the Swiftdoc privacy statement is protected by Australian copyright law.</p>

                </div>

            </Layout>
        </AppStateProvider>
    )
}

export default PrivacyIndex


